<template>
  <v-layout v-if="_.size(detail) > 0" row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <titleCard title="Mensaje del pedido" subtitle="Corregir ortografia si es necesario"></titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap text-xs-center>
            <v-flex xs12>
              <QuillEditorFormat
                v-model="detail.message"
                :reference="'editorOrder'"
                placeholder="Mensaje de la tarjeta"
              ></QuillEditorFormat>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions align-end class="text-xs-right" bottom>
          <v-spacer></v-spacer>
          <v-btn color="secondary" :loading="loading" :disabled="loading" depressed @click="updateMessage">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { GET_API, ORDER } from '../../config'
import titleCard from '../useful/titleCard.vue'
import QuillEditorFormat from '../useful/quillEditorFormat.vue'

export default {
  name: 'OrderMessage',
  components: { titleCard, QuillEditorFormat },
  props: ['order', 'getOrder'],
  data() {
    return {
      detail: null,
      loading: false,
      content: null,
      editorOption: {
        placeholder: 'Mensaje aquí'
      }
    }
  },
  watch: {
    order() {
      this.detail = this.order
    }
  },
  mounted() {
    this.detail = this.order
    this.$store.dispatch('updateTitle', `Mensaje - Pedido ${this.order.id}`)
  },
  methods: {
    async updateMessageAction() {
      try {
        this.loading = true
        await this.$http.post(`${ORDER}/${this.order.id}/message`, {
          id: this.detail.id,
          message: this.detail.message
        })
        this.loading = false
        this.$store.dispatch('setConfirm', {
          active: false,
          title: '',
          description: '',
          action: {}
        })
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    fontReturn(type) {
      const myFonts = [
        'Dancing Script',
        'Nothing You Could Do',
        'Great Vibes',
        'Marck Script',
        'Homemade Apple',
        'Reenie Beanie',
        'Just Me Again Down Here',
        'Caveat',
        'Dawning of a New Day',
        'Cedarville Cursive'
      ]
      let font_names = 'Georgia;Times New Roman/Times New Roman, Times, serif'
      for (let i = 0; i < myFonts.length; i++) {
        font_names = `${font_names};${myFonts[i]}`
        myFonts[i] = `https://fonts.googleapis.com/css?family=${myFonts[i].replace(' ', '+')}`
      }
      if (type === 'css') {
        return ['/ckeditor/contents.css'].concat(myFonts)
      }
      return font_names
    },
    updateMessage() {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Cambiar mensaje?',
        description: 'Al ser modificado, se guardara una copia del mensaje en el historial.',
        action: this.updateMessageAction
      })
    }
  }
}
</script>
